const reloadButton = document.querySelector('.cmp-reload-button');

const triggerAnimationOnReload = () => {
  reloadButton.addEventListener('click', (event) => {
    event.preventDefault();
    reloadButton.classList.add('cmp-reload-button--animated');
    document.querySelector('html').classList.add('is-leaving');
    // introduce a slight delay to prevent the animation from seeming janky
    setTimeout(() => {
      window.location.href = '/';
    }, 250);
  });
};

const resetAnimationOnAnimationEnd = () => {
  reloadButton.addEventListener('animationend', () => {
    reloadButton.classList.remove('cmp-reload-button--animated');
  });
};

const activateReloadButton = () => {
  triggerAnimationOnReload();
  resetAnimationOnAnimationEnd();
};

module.exports = { activateReloadButton };
