const focusGem = () => {
  const gem = document.querySelector('#jump-to-gem-on-reload');
  gem.setAttribute('tabindex', '0');
  gem.focus();
};

const addGemReloadVoiceover = () => {
  const gemReloadAria = document.querySelector('.cmp-gem__reload-aria');
  gemReloadAria.textContent = 'gem updated';
};

export const focusGemOnReload = () => {
  addGemReloadVoiceover();
  focusGem();
};
